
.ant-modal.xls-modal {
    top: 0 !important;
    height: 100%;
    width: 100% !important;
    padding-bottom: 0;
}

.xls-modal .ant-modal-content {
    border-radius: 0;
    height: 100%;
}

.xls-modal .ant-modal-body {
    padding: 0;
}

.xls-modal-wrapper {
    overflow: initial !important;
}

.xls-action-panel {
    height: 100%;
    border-left: 2px solid #eee;
}


.react-datepicker { font-size: 1em !important }
.react-datepicker__header { padding-top: 0.8em !important}
.react-datepicker__month { margin: 0.4em 1em !important}
.react-datepicker__day-name, .react-datepicker__day {
    width: 1.9em !important;
    line-height: 1.9em !important;
    margin: 0.166em !important;
}
.react-datepicker__current-month { font-size: 1em !important }
.react-datepicker__navigation {
    top: 1em !important;
    line-height: 1.7em !important;
    border: 0.45em solid transparent !important;
}
.react-datepicker__navigation--previous { border-right-color: #ccc !important; left: 1em !important }
.react-datepicker__navigation--next {
    border-left-color: #ccc !important;
    right: 1em !important;
    left: 220px !important
}


.react-datepicker__time-container { width: 6em !important}
.react-datepicker-time__header { font-size: 1em !important }
.react-datepicker-time__header--time { padding-left: 0px !important; padding-right: 0px !important }
.react-datepicker__time-box {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width:100% !important
}
.react-datepicker__time-list { padding: 0 !important; }

.ant-tooltip-inner {
    white-space: pre-wrap;
}
label.ant-radio-wrapper, .ant-checkbox-wrapper {
    font-weight: normal;
}

.xls-filter-bar .ant-radio-wrapper, .xls-filter-bar .ant-checkbox-wrapper {
    margin-right: 0;
    font-size: 12px;
}

.xls-filter-bar span.ant-radio + * {
    padding-left: 4px;
}

.ant-select-dropdown-menu-item {
    white-space: normal;
}
